<script setup lang="ts">
import { nanoid } from 'nanoid'
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import {
  hasFieldValues,
  type INodeFieldValue,
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const multiple = props.node.multiple ?? false
const values = computed(() =>
  hasFieldValues(props.node)
    ? props.node.values?.map<INodeFieldValue & { __id: string }>((val) => ({
        ...val,
        __id: nanoid(),
      }))
    : undefined,
)

const {
  modelValue,
  parentValue,
  id,
  type,
  colSize,
  label,
  path,
  validation: { errorMessage },
  help,
  disabled,
  min,
  max,
  handleChange,
  handleBlur,
} = useField(props, emit)

const { castPlugin } = useFieldCast(props.node)

// Initialize calculated field, after setting default value
const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

// Initialize data field, after setting default value
const { registerData } = useFieldData(props.node, parentValue)
registerData()

useFieldCache(props.node, parentValue, props.preserveCache)

const vModels = ref<Record<string, boolean>>(
  values.value?.reduce(
    (acc, val) => {
      acc[val.__id] = modelValue.value === val.value
      return acc
    },
    {} as Record<string, boolean>,
  ) ?? {},
)

const onChange = (
  event: Event,
  { __id, value }: INodeFieldValue & { __id: string },
) => {
  if (!values.value) return
  for (const val of values.value) {
    if (val.__id !== __id) {
      vModels.value[val.__id] = false
    }
  }

  const _value = (event.target!.checked as boolean) ? value : null

  modelValue.value = _value
  handleChange(_value)
}
</script>

<template>
  <ManagerSharedFieldBase
    :path="path"
    :col-size="colSize"
    :tooltip="node.tooltip"
    :help="help"
    :error-message="errorMessage"
    :usage="node.usage"
  >
    <!-- Multiple Checkbox --- Array Value -->
    <FormKit
      v-if="multiple"
      :id="id"
      v-model="modelValue"
      type="checkbox"
      :name="node.name"
      :label="label"
      :options="values"
      label-by="key"
      track-by="value"
      outer-class="w-full"
      label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
      :plugins="[castPlugin]"
      :disabled="disabled"
      :min="min"
      :max="max"
      @input="handleChange"
      @blur="handleBlur"
    />

    <!-- Exclusive Checkbox --- Single Value -->
    <div v-else-if="values" class="flex gap-4">
      <FormKit
        v-for="value in values"
        :id="value.__id"
        :key="value.__id"
        v-model="vModels[value.__id]"
        type="checkbox"
        :name="node.name + '_' + value.__id"
        :label="value.key"
        label-by="key"
        track-by="value"
        outer-class="w-full"
        label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
        :plugins="[castPlugin]"
        :disabled="disabled"
        :min="min"
        :max="max"
        @change="onChange($event, value)"
      />
    </div>

    <!-- Default Checkbox --- Boolean -->
    <FormKit
      v-else
      v-model="modelValue"
      type="checkbox"
      :name="node.name"
      :label="label"
      label-by="key"
      track-by="value"
      outer-class="w-full"
      label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
      :plugins="[castPlugin]"
      :disabled="disabled"
      :min="min"
      :max="max"
      @input="handleChange"
      @blur="handleBlur"
    />
  </ManagerSharedFieldBase>
</template>
